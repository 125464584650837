<template>
  <el-container>
    <el-aside class="left-aside" width="auto">
      <div class="logo-area" @click="toIndex">
        <img v-if="collapse" src="~@/assets/images/mgt-home/tgLogo1.png" alt="">
        <img v-else src="~@/assets/images/mgt-home/ic-logo.svg" alt="">
      </div>
      <div class="aside-area">
        <el-menu
          class="menu-list"
          @select="handleSelect"
          :collapse-transition="false"
          :default-active="currentMenu"
          :collapse="collapse"
          background-color="#333333"
          text-color="#FFFFFF"
          style="border: none"
        >
          <tg-menu-body :menu-list="menuList"></tg-menu-body>
        </el-menu>
      </div>
    </el-aside>

    <el-container>
      <el-header style="padding: 0">
        <div class="user-info-bar">
          <div class="fold-btn-area">
            <div v-if="collapse" class="fold-btn" @click="handleClick">
              <i class="el-icon-s-unfold"></i>
            </div>
            <div v-else class="fold-btn" @click="handleClick">
              <i class="el-icon-s-fold"></i>
            </div>
          </div>
          <div class="user-info-pane">
            <el-dropdown trigger="click" @command="handleClickUserInfo">
              <label class="el-dropdown-link">
                {{userinfo.nickname}}<i class="el-icon-arrow-down el-icon--right"></i>
              </label>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="index">返回主页</el-dropdown-item>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="tags-area" v-if="tagBarShow">
          <tg-tags-bar
            ref="tags-bar"
            :default-tag="defaultTag"
            :visited-tag-list="VisitedTagList"
            @addTag="handleAddTag"
            @removeTag="handleRemoveTag"
            @updateTag="handleUpdateTag"
          >
          </tg-tags-bar>
        </div>
      </el-header>
      <el-container :class="fixedHeader?'fixed-header':'children-page-container'">
        <keep-alive>
          <router-view v-if="$route.meta.isCache"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.isCache"></router-view>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import authorization from '@/store/utils/authorization'
import systemConfig from '@/config/system-config'
export default {
  name: 'Home',
  data() {
    return {
      visibleFlag: true,
      // 标签栏显示
      tagBarShow: systemConfig.TAGS_SHOW,
      // 固定头部
      fixedHeader: systemConfig.FIXED_HEADER,
      // 高亮菜单
      currentMenu: this.$route.fullPath,
      // 展开收缩
      collapse: false,
      menuList: []
    }
  },
  computed: {
    VisitedTagList() {
      return this.$store.getters.getVisitedTagList || []
    },
    userinfo() {
      return authorization.getUserInfo()
    },
    // 默认标签
    defaultTag() {
      return this.$store.getters.getDefaultTag
    }
  },
  watch: {
    $route(n, o) {
      this.currentMenu = n.fullPath
    }
  },
  mounted() {
    if (this.menuList.length === 0) {
      this.menuList = this.$store.getters.getUserMenuList
    }
  },
  methods: {
    // 点击logo区域
    toIndex() {
      this.$router.push('/index')
    },
    // 处理标签新增
    handleAddTag(et) {
      const tags = this.VisitedTagList.concat([{
        path: et.path,
        label: et.meta.menuName
      }])
      this.$store.commit('SET_TAGS', tags)
    },
    // 处理关闭标签
    handleRemoveTag(et) {
      this.$store.commit('SET_TAGS', this.VisitedTagList.filter((item, index) => {
        return item.path !== et.path
      }))
    },
    // 处理标签数据更新（关闭左边、关闭右边、关闭其他）
    handleUpdateTag(et) {
      this.$store.commit('SET_TAGS', et)
    },
    handleSelect(index, indexPath) {
      this.$router.push(index).catch(() => {
        return true
      })
    },
    handleClick() {
      this.collapse = !this.collapse
    },
    // 点击用户信息下拉列表时
    handleClickUserInfo(cmd) {
      if (cmd === 'index') {
        this.$router.push('/index').catch(() => {
          return true
        })
      }
      if (cmd === 'logout') {
        this.$router.push('/logout').catch(() => {
          return true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 左侧边栏
.left-aside {
  min-height: 100vh;
  background-color: #333333;
  // logo区域
  .logo-area {
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    &:hover{
      box-shadow: inset 0 0 10px #e8413d;
    }
    img {
      height: 40px;
    }
  }

  // 导航菜单区域
  .aside-area {
    background-color: #333333;
    overflow: hidden;
    // 使用菜单折叠时，注意引入以下样式以避免显示异常
    .menu-list:not(.el-menu--collapse) {
      width: 200px;
      min-height: 400px;
    }

    ::v-deep.el-menu--collapse {
      .el-submenu__title span {
        display: none;
      }

      .el-submenu__title .el-submenu__icon-arrow {
        display: none;
      }
    }
  }
}

.el-header {
  height: auto !important;
  border-bottom: 1px solid #e6e6e6;

  .user-info-bar {
    height: 36px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    .fold-btn-area {
      .fold-btn {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .tags-area {
    max-height: 30px;
    padding: 0 20px;
    margin-bottom: 4px;
  }
}

.children-page-container{
  background-color: #f2f2f2;
}

.fixed-header{
  background-color: #f2f2f2;
  height: calc(100vh - 70px);
  overflow: auto;
}
</style>
